import axios from 'axios'
import store from '../store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_CORE_ADMIN_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  if (store.getters.isAuthenticated) {
    config.headers.Authorization = store.getters.token
      ? `Bearer ${store.getters.token}`
      : ''
  }
  return config
})

export const fetchGuests = async (page, limit, id) => {
  const params = {}
  if (page !== undefined) params.page = page
  if (limit !== undefined) params.limit = limit
  if (id !== undefined && id !== '') params.id = id

  const response = await instance.get('/guests', { params })
  return response.data
}

export const deleteGuest = async (guestId) => {
  const response = await instance.delete(`/guests/${guestId}`)
  return response.data
}
