export const auspiciousDateSchema = {
  date: '',
  start_time: '',
  end_time: '',
}

export default {
  marriage: {
    engagement: auspiciousDateSchema,
    betrothal: auspiciousDateSchema,
    preparing_bed: auspiciousDateSchema,
    sewing_gown: auspiciousDateSchema,
    hair_combing: auspiciousDateSchema,
    arrival: auspiciousDateSchema,
  },
  marriage_registration: {
    marriage_registration: auspiciousDateSchema,
  },
  new_car: {
    car_purchasing: auspiciousDateSchema,
  },
  birth: {
    cesarean: auspiciousDateSchema,
  },
  gift_icsi: {
    gift_icsi: auspiciousDateSchema,
  },
  non_residential_construction: {
    starting_construction: auspiciousDateSchema,
    setting_up_shrine: auspiciousDateSchema,
    ground_breaking: auspiciousDateSchema,
  },
  residential_construction: {
    starting_construction: auspiciousDateSchema,
    setting_up_shrine: auspiciousDateSchema,
    ground_breaking: auspiciousDateSchema,
  },
  house_moving: {
    setting_up_shrine: auspiciousDateSchema,
    entering_house: auspiciousDateSchema,
    worshiping_lord: auspiciousDateSchema,
  },
  contract_signing: {
    contract_signing: auspiciousDateSchema,
  },
  business_opening: {
    setting_up_shrine: auspiciousDateSchema,
    business_opening: auspiciousDateSchema,
    worshiping_lord: auspiciousDateSchema,
  },
  surgery: {
    surgery: auspiciousDateSchema,
  },
  treatment: {
    treatment: auspiciousDateSchema,
  },
  cosmetic_surgery: {
    cosmetic_surgery: auspiciousDateSchema,
  },
  beauty: {
    beauty: auspiciousDateSchema,
  },
  exam: {
    exam: {
      ...auspiciousDateSchema,
      blessing: '',
    },
  },
  study_abroad: {
    study_abroad: auspiciousDateSchema,
  },
  new_pet: {
    new_pet: auspiciousDateSchema,
  },
  product_launch: {
    product_launch: auspiciousDateSchema,
  },
}
