import { fetchGuests, deleteGuest } from '@/api/axios-guests'
import { downloadCsv, objectsToCsv } from '@/helpers/csv_exporter'

const state = {
  guests: [],
  guestsIsLoading: false,
  totalPage: 1,
  totalGuests: 0,
  currentPage: 1,
  itemsPerPage: 10,
}

const mutations = {
  SET_GUESTS(state, guests) {
    state.guests = guests
  },
  SET_GUESTS_LOADING(state, isLoading) {
    state.guestsIsLoading = isLoading
  },
  SET_TOTAL_PAGE(state, totalPage) {
    state.totalPage = totalPage
  },
  SET_TOTAL_GUESTS(state, totalGuests) {
    state.totalGuests = totalGuests
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page
  },
}

const actions = {
  async fetchGuests({ commit, state }, searchTerm = '') {
    commit('SET_GUESTS_LOADING', true)
    try {
      const response = await fetchGuests(
        state.currentPage,
        state.itemsPerPage,
        searchTerm
      )
      const guests = response.guests.map((it) => ({ ...it, status: 'Guest' }))
      const totalGuests = response.meta.pagination.total
      const totalPage = response.meta.pagination.pages
      const currentPage = searchTerm ? 1 : response.meta.pagination.page

      commit('SET_GUESTS', guests)
      commit('SET_TOTAL_PAGE', totalPage)
      commit('SET_TOTAL_GUESTS', totalGuests)
      commit('SET_CURRENT_PAGE', currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_GUESTS_LOADING', false)
    }
  },
  async deleteGuest({ commit, state }, guestId) {
    try {
      await deleteGuest(guestId)
      const updatedGuests = state.guests.filter((guest) => guest.id !== guestId)
      commit('SET_GUESTS', updatedGuests)
    } catch (error) {
      console.error(error)
    }
  },
  async exportGuests({ state }) {
    try {
      const data = state.guests.map(({ id, status, created_at, os }) => ({
        id,
        status,
        open_date: created_at,
        os,
      }))
      downloadCsv(objectsToCsv(data), `guests_${new Date().getTime()}.csv`)
    } catch (error) {
      console.error(error)
    }
  },
  setCurrentPage({ commit, dispatch }, page) {
    commit('SET_CURRENT_PAGE', page)
    dispatch('fetchGuests')
  },
}

const getters = {
  guests: (state) => state.guests,
  guestsIsLoading: (state) => state.guestsIsLoading,
  totalPage: (state) => state.totalPage,
  totalGuests: (state) => state.totalGuests,
  currentPage: (state) => state.currentPage,
  itemsPerPage: (state) => state.itemsPerPage,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
